import type {PropsWithChildren, ReactElement} from 'react';
import {Section, Content} from "./styles";
import { Container } from '@mui/material';

type SectionContentProps = PropsWithChildren<{
}>

export function SectionContent({children}: SectionContentProps): ReactElement {
    return (
        <Container maxWidth="md">
        <Section style={{}}>
            <Content>{children}</Content>
        </Section>
        </Container>
    )
}
