import { objectNodes } from "./utils";
import { ProjectTreeItem, ProjectTreeItemComponentData, ProjectTreeItemData, ProjectTreeItemType } from "@grenton/gm/ui/components/projectComponentTree2";
import { ObjectReferences2, ProjectImpl, ProjectObjectImpl, sortTags } from "@grenton/gm-logic";
import { objectIconResolver } from "@grenton/gm/ui/icon-resolver/objectIconResolver";
import { GTreeItemCheckboxState } from "@grenton/design-system";
import { encodeTreeItemId } from "./utils/id";
import { MainTreeContext } from "./utils/treeContext";

type Props = {
    project: ProjectImpl;
    tagCategory: string;
    multiSelectMode: boolean;
    references: ObjectReferences2;
};

/*
* 
* main tree model:
* 
* - 1st level items are groups: by component or by tag from selected category
* - 2nd level items are top-level objects (roots) that belong to a group
* - 3rd level items are (in order):
*       -  for script root objects : list of methods 
*       -  for all objects : list of outlets (skip "parent" outlet, hide "object" outlet)
* - 4th level items appear only under outlets:
*       - for script root objects : event handlers of outlets
*       - for all objects : statically referenced objects with all nested items (go back to 2nd level)
*       - for all objects, show on demand : dynamically referenced objects, but w/o nested items.
* 
* generally we want to show all objects and their protocol items.
* for events and methods (scriptable items) we need to know scriptPath from the root object. 
* root object is an owner of the script. it is either scriptable object itself or its anonymous controller.
* 
* every time when we display "object" item, we treat it as a root.
* 
* WARNING: duplicated nodes (with the same id) ARE PROHIBITED by RichViewTree although they work with SimpleViewTree!
* we need to construct id of each node with use of parent id and object id to avoid duplications.
* the consequence is that we cannot easily map node id to object id and vice versa, because it is many to one relation.
*/
export function mainObjectTreeModel({ project, tagCategory, references }: Props): ProjectTreeItem<ProjectTreeItemData>[] {

    const iconResolver = objectIconResolver(project.firmware)
    const objectResolver = project.objectResolver

    const ctx: MainTreeContext = {
        iconResolver,
        objectResolver,
        references
    }

    if (!tagCategory) {

        const objectsByModule = project.topObjects
            .filter(o => o.impl.type === 'module')
            .reduce((acc, object) => {
                const id = object.impl.componentRef.componentId || ''
                return { ...acc, [id]: (acc[id] || []).concat([object]) }
            }, {} as { [mid: string]: ProjectObjectImpl[] })

        const moduleItems = project.modules
            .filter(mod => mod.type === 'module')
            .map((mod) => {
                const sortKey = `${mod.ref} ${mod.uuid}`
                const id = encodeTreeItemId(`mod:${mod.uuid}`)
                const item: ProjectTreeItem<ProjectTreeItemComponentData, ProjectTreeItemData> = {
                    id,
                    label: id,
                    icon: null,
                    sortKey,
                    data: {
                        type: ProjectTreeItemType.MODULE,
                        module: {
                            id: mod.uuid,
                            ref: mod.ref,
                            type: mod.type,
                        }
                    },
                    children: objectNodes({
                        treeContext: ctx,
                        parentId: id,
                        objects: objectsByModule[mod.uuid] || [],
                    })
                }
                return item
            })
        const nonModuleObjects = objectNodes({
            treeContext: ctx,
            parentId: encodeTreeItemId('mod:*'),
            objects: project.topObjects.filter(o => o.impl.type !== 'module')
        })
        return [...moduleItems, ...nonModuleObjects]
    } else {
        const tags = [...project.tags.byCategoryName(tagCategory), ""].sort(sortTags);

        return tags.map((tag: string) => {
            const id = encodeTreeItemId(`tag:${tag}`);
            const filteredObjects = project.topObjects.filter(object =>
                (tag && object.tags.includes(tag)) ||
                (!tag && object.tags.empty) ||
                Object.values(object.init.outlets || {}).some(outlet =>
                    outlet.staticRefs && outlet.staticRefs.some(staticRef =>
                        project.objects[staticRef]?.tags.selected.includes(tag)
                    )
                )
            )

            const children = objectNodes({
                treeContext : {...ctx, rootTag:tag},
                parentId: id,
                objects: filteredObjects
            })

            const tagValue = tag.split(':')[1] || `\u2217`
            return {
                id,
                label: tagValue,
                icon: null,
                sortKey: tagValue,
                data: {
                    type: ProjectTreeItemType.SPACE,
                    tag: tagValue
                },
                children,
                checkbox: GTreeItemCheckboxState.HIDDEN
            };
        });
    }
}

