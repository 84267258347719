import { OutletImpl } from '@grenton/gm-logic';
import { ProjectTreeItemOutletData, ProjectTreeItem, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';

export function outletNode(parentId: string, path: string[], outlet: OutletImpl): ProjectTreeItem<ProjectTreeItemOutletData> {
    return {
        id: `${parentId}.${outlet.id}`,
        label: outlet.name,
        sortKey: outlet.name,
        icon: 'outlet',
        data: {
            type: ProjectTreeItemType.OUTLET,
            path,
            outletId: outlet.id,
        },
        children: [],
    };
}
