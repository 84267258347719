import type {ReactElement} from "react";
import {Box, Button, Stack} from "@mui/material";
import {ObjectEditForm} from "@grenton/gm-logic";
import {ObjectEditPane} from "./objectEditPane";
import { ObjectPropertiesFormMeta } from "./objectEditPane/types";
import { GButtonBar } from "@grenton/design-system";
import { ValidatorResult } from "../../../ui/form-validation";

type Props = {
    errors: ValidatorResult
    form: ObjectEditForm;
    meta: ObjectPropertiesFormMeta;
    onDelete: (uuids: string[]) => void;
    onClose: () => void;
    onSave: () => void;
    onChange: (form: ObjectEditForm, modified:boolean) => void;
}

export function ObjectEditTab({form, meta, errors, onChange, onSave, onDelete, onClose}: Props): ReactElement | null {
    if (!form || !meta) return null;

    const validatedForm = errors.valid
    return (<Stack sx={{height:'100%', flexDirection:'column'}}>      
            <Box sx={{flexGrow:1, overflow:'hidden', display:'flex'}} className="props-content">
                <ObjectEditPane
                    meta={meta} 
                    form={form} 
                    errors={errors}
                    onChange={onChange}
                />
            </Box>
            <GButtonBar 
                start={
                    <><Button size="small" onClick={onClose}>Close</Button>
                    <Button size="small" disabled={meta.delete === 'none'} 
                        onClick={()=>onDelete(form.id)}>{meta.delete === 'module' ? "Delete module" : "Delete object"}
                    </Button>
                    </>
                }
                end={<>
                    
                    <Button size="small" color="secondary" disabled={!validatedForm} onClick={onSave}>Save</Button>
                    <Button size="small" color="primary" disabled={!validatedForm} onClick={() => {onSave(); onClose();}}>Save and Close</Button>
                </>}
            />
            </Stack> 
    );
}
