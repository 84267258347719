import { createContext } from 'react';
import { ObjectEditModel } from './types';
import { ObjectEditForm } from '@grenton/gm-logic';

function paneId(ids: string[]) {
    return ids.join('|');
}

export class ObjectsEditModel {
    constructor(
        readonly open: boolean,
        readonly selectedTab: number,
        readonly objects: { [id: string]: ObjectEditModel },
    ) {}
    /**
     * @param objectId
     * @param updated - if true, mark form as modified, ignore otherwise
     * @returns
     */
    withFormState(objectId: string[], updated: boolean): ObjectsEditModel {
        const key = paneId(objectId);
        let om = this.objects[key];
        return om ? this.withObjectModel({ ...om, modified: om.modified || updated }, false) : this;
    }
    withModified(objectId: string[], modified: boolean) {
        const key = paneId(objectId);
        let om = this.objects[key];
        return om ? this.withObjectModel({ ...om, modified }, false) : this;
    }
    withOpen(open: boolean): ObjectsEditModel {
        return new ObjectsEditModel(open, this.selectedTab, this.objects);
    }
    withObjectModel(model: ObjectEditModel, selectIt = true) {
        const key = paneId(model.form.id);
        const m = new ObjectsEditModel(this.open, this.selectedTab, { ...this.objects, [key]: model });
        return selectIt ? m.withSelectedTab(Object.keys(m.objects).indexOf(key)) : m;
    }
    getObjectModel(id: string[]) {
        const key = paneId(id);
        return this.objects[key];
    }
    withObjectForm(form: ObjectEditForm) {
        const key = paneId(form.id);
        const model = this.objects[key];
        if (!model) return this;
        return this.withObjectModel({ ...model, form }, false);
    }
    ensureObjectsExist(checker: (id: string) => boolean) {
        let _objectsEditModel: ObjectsEditModel = this;
        Object.entries(this.objects).forEach(([paneId, model]) => {
            const objectRemoved = model.form.id.some((id) => !checker(id));
            if (objectRemoved) {
                _objectsEditModel = _objectsEditModel.withoutObject([paneId]);
            }
        });
        return _objectsEditModel;
    }
    get hasObjects() {
        return Object.keys(this.objects).length > 0;
    }
    get hasSelectedTab() {
        return this.selectedTab !== -1;
    }
    get count() {
        return Object.keys(this.objects).length;
    }
    withSelectedTab(tab: number) {
        return new ObjectsEditModel(this.open, tab, this.objects);
    }
    withoutObject(id: string[]) {
        const key = paneId(id);
        const index = Object.keys(this.objects).indexOf(key);
        if (index === -1) return this;
        const selectedObject = this.selectedTab >= 0 ? Object.keys(this.objects)[this.selectedTab] : undefined;
        const objects = { ...this.objects };
        delete objects[key];
        const hasObjects = Object.keys(objects).length > 0;
        let selectedTab = this.selectedTab;

        if (selectedObject === key) {
            // deleted was selected, next one will be selected automatically, of previous one if there's no next to it
            if (selectedTab >= Object.keys(objects).length) {
                selectedTab = selectedTab - 1;
            }
        } else {
            // reselect object
            selectedTab = selectedObject ? Object.keys(this.objects).indexOf(selectedObject) : -1;
        }

        return new ObjectsEditModel(hasObjects ? this.open : false, selectedTab, objects);
    }
}

export const ObjectsEditContext = createContext<ObjectsEditModel>(new ObjectsEditModel(false, -1, {}));
