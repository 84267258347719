import { Add } from "@mui/icons-material";
import { Breadcrumbs, IconButton, Link, Tab } from "@mui/material";
import React from "react";
import { ObjectResolver, ProjectObjectApi } from "@grenton/gm-logic";
import { ScriptWithContext } from "@grenton/gm-logic";
import { OUTLET_OBJECT } from "@grenton/gm-common";
import { GTabs } from "@grenton/design-system";

export function EditorToolbar(props: {
  // controller ref+script ref
  editedScript: ScriptWithContext,
  resolver: ObjectResolver,
  onObjectEdit: (objectId: string) => void,
  onOutletEdit: (objectId: string, outletId: string) => void,
  onMethodEdit: (objectId: string, methodId: string) => void
}) {
  const nav = []
  const { editedScript, onObjectEdit: onEditObject, onOutletEdit, onMethodEdit } = props

  // controller itself or object associated to anonymous controller
  const sourceObject = editedScript.object.getFirstChild(OUTLET_OBJECT, props.resolver) ?? editedScript.object

  nav.push({
    name: sourceObject.label, onClick: () => {
      onEditObject(sourceObject.uuid)
    }
  })


  let currentProtocol = editedScript.object.api
  editedScript.scriptRef.path.split('.').forEach(elem => {

    const i = getProtocolItem(currentProtocol, elem)
    switch (i?.type) {
      case 'outlet': {
        currentProtocol = i.item.api
        nav.push({
          name: i.item.name, onClick: () => {
            onOutletEdit(editedScript.object.uuid, elem)
          }
        })
        break
      }
      case 'method': {
        nav.push({
          name: i.item.name, onClick: () => {
            onMethodEdit(editedScript.object.uuid, elem)
          }
        })
        break
      }
      case 'event': {
        nav.push({
          name: i.item.name
        })
        break
      }
    }
  })

  const [segment, setSegment] = React.useState(0)

  return <React.Fragment>
    <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 14 }} >
      {
        nav.map((n, i) =>
          <Link key={i} sx={{ cursor: 'pointer' }}
            underline="hover" onClick={n.onClick} color="inherit">
            {n.name}
          </Link>

        )}
      <div />
    </Breadcrumbs>


    <GTabs value={segment} sx={{ fontSize: 10, mb: 1, mr: 1, gap: 0, '& .MuiTabs-flexContainer': { gap: 0 } }} onChange={(_, s) => setSegment(s)}>
      <Tab value={0} label="script 1" />
    </GTabs>
    <IconButton><Add /></IconButton>
  </React.Fragment>
}


function getProtocolItem(protocol: ProjectObjectApi, itemId: string) {
  const maybeOutlet = protocol.outlets[itemId]
  if (maybeOutlet) {
    return {
      type: 'outlet' as const,
      item: maybeOutlet
    }
  }
  const maybeMethod = protocol.methods[itemId]
  if (maybeMethod) {
    return {
      type: 'method' as const,
      item: maybeMethod
    }
  }
  const maybeEvent = protocol.events[itemId]
  if (maybeEvent) {
    return {
      type: 'event' as const,
      item: maybeEvent
    }
  }
  return null
}