import type { ReactElement } from "react";
import { Box, ToggleButton, Button } from "@mui/material";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import { useCallback, useState } from "react";
import { Lists } from "@grenton/gm-common";
import { GButtonBar, GProfiler, ObjectTreeFilter, ScrollablePaneWithHeader, grentonColors } from "@grenton/design-system";
import { ObjectScriptRef, ProjectObjectImpl } from "@grenton/gm-logic";
import { MainObjectTree } from './components';
import type { MouseEventHandler } from 'react';
import { ProjectImpl } from '@grenton/gm-logic';
import { ObjectMultiSelection } from '../../utils';
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeFilterResult, ProjectTreeItemType } from "@grenton/gm/ui/components/projectComponentTree2";
import { Clear } from "@mui/icons-material";

export type MainObjectTreePaneProps = {
    project: ProjectImpl;
    selectedObject: string | null;

    multiSelection: ObjectMultiSelection;
    onMultiSelectionEdit: ()=>void
    onMultiSelectionChange: (m: ObjectMultiSelection) => void;
    onMultiSelectionToggle: (enabled:boolean) => void;

    onPrimaryAction: (action:
        { type: 'event', data: ObjectScriptRef } |
        { type: 'method', data: ObjectScriptRef } |
        { type: 'object', data: { objectId: string, rootTag?: string } }) => void;

    onSecondaryAction: (action:
        { type: 'event', data: { path: string[] } } |
        { type: 'method', data: { path: string[] }  } |
        { type: 'outlet', data: { path: string[] } } |
        { type: 'object', data: { path: string[], objectId: string, rootTag?: string } }) => void;

    onOpenLibrary: MouseEventHandler;
};

export function MainObjectTreePane(props: MainObjectTreePaneProps): ReactElement {

    const { project, selectedObject, multiSelection, onMultiSelectionToggle, onMultiSelectionChange, onPrimaryAction, onSecondaryAction, onOpenLibrary } = props;
    const [filter, setFilter] = useState("");
    const [selectedTagCategory, setSelectedTagCategory] = useState<string | null>(null);

    const filterFn = useCallback((node: ProjectTreeItem<ProjectTreeItemData>) => {
        if (!filter.length) return ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN
        switch (node.data.type) {
            case ProjectTreeItemType.OBJECT:
                return node.sortKey.includes(filter) ? ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN : ProjectTreeFilterResult.EXCLUDE
            default:
                return ProjectTreeFilterResult.INCLUDE_NOT_EMPTY
        }
    }, [filter])

    function onTreeItemClick(node: ProjectTreeItem<ProjectTreeItemData>): void {
        const data = node.data
        switch (data.type) {
            case ProjectTreeItemType.EVENT: {
                onPrimaryAction({
                    type: 'event',
                    data: {
                        objectId: data.path[0]!,
                        scriptRef: {
                            type: 'event',
                            path: data.path.slice(1).join('.'),
                        }
                    }
                })
                //onSelectionChange(selection.withSingleObject(data.sourceObjectId))
                break
            }
            case ProjectTreeItemType.METHOD: {
                onPrimaryAction({
                    type: 'method',
                    data: {
                        objectId: data.path[0]!,
                        scriptRef: {
                            type: 'method',
                            path: data.path.slice(1).join('.')
                        }
                    }
                })
                break
            }
            case ProjectTreeItemType.OBJECT: {
                onPrimaryAction({ type: 'object', data })
                break
            }
        }
    }

    function onTreeItemActionClick(item: ProjectTreeItem<ProjectTreeItemData>): void {
        const data = item.data
        switch (data.type) {
            case ProjectTreeItemType.OBJECT: {
                // here objectId points to module/controller object
                onSecondaryAction({ type: 'object', data })
                break
            }
            case ProjectTreeItemType.OUTLET: {
                // here as well
                onSecondaryAction({ type: 'outlet', data: {path:data.path} })
                break
            }
            case ProjectTreeItemType.METHOD: {
                // these nodes are only rendered for non-hardware objects
                onSecondaryAction({
                    type: 'method',
                    data: {
                        // objectId: data.path[0]!,
                        // scriptRef: {
                        //     type: 'method',
                        //     path: data.path.slice(1).join('.'),
                        // }
                        path: data.path
                    }
                })
                break
            }
            case ProjectTreeItemType.EVENT: {
                // these nodes are only rendered for non-hardware objects
                onSecondaryAction({
                    type: 'event',
                    data: {
                        // but here objectId may point to anonymous controller, but sourceObjectId always points to the object that defines this event
                        // objectId: data.path[0]!,
                        // scriptRef: {
                        //     type: 'event',
                        //     path: data.path.slice(1).join('.'),
                        // }
                        path: data.path
                    }
                })
                break
            }
        }
    }

    const onObjectMultiSelectionToggle = (objectId: string, checked: boolean) => {
        if (!multiSelection.mode) return

        const nodes = { ...multiSelection.objects, [objectId]: checked }

        const allObjects: [ProjectObjectImpl | undefined, boolean][] = Object.entries(nodes)
            .filter(n => n[1] !== undefined)
            .map(n => [project.getObjectById(n[0]), n[1]])

        //first selected api
        const firstSelected = allObjects.find(n => n[1] === true)
        const api = firstSelected ? firstSelected[0]?.api : undefined

        // then, allow selection only these nodes that implement this api
        const compatibleNodes = api ?
            // narrow down selectable nodes to those that implement the same API as the first selected node
            Lists.reduce(allObjects.filter(n => n[0] && n[0].api.name === api.name), (n) => [n[0]!.uuid, !!nodes[n[0]!.uuid]]) :

            // reset to all objects, mark all as unselected
            Lists.reduce(Object.values(project.objects), (obj) => [obj.uuid, false])

        onMultiSelectionChange(multiSelection.withObjects(compatibleNodes))
    }

    return (
        <Box sx={{ height: '100%', position: 'relative', flexGrow:0, minWidth:220, background: grentonColors.backgrounds_treepanes }}>
            <ScrollablePaneWithHeader header={
                <ObjectTreeFilter
                    filterPattern={filter}
                    onFilterPatternChange={txt => setFilter(txt?.trim())}
                    tagCategories={project.tags.categories}
                    onTagCategoryChange={setSelectedTagCategory}
                    selectedTagCategory={selectedTagCategory}
                />
            } footer={
                <GButtonBar end={<ToggleButton
                size="small"
                    sx={{ border: 'none' }}
                    value="check" 
                    selected={multiSelection.mode}
                    onClick={()=>onMultiSelectionToggle(!multiSelection.mode)}
                >
                    {multiSelection.mode ? <Clear/> : <LibraryAddCheckOutlinedIcon /> }
                </ToggleButton>} 
                start={
                    multiSelection.mode ? <Button size="small" color="secondary" disabled={multiSelection.empty} onClick={props.onMultiSelectionEdit}>Edit {multiSelection.selected.length} objects</Button> : 
                <Button size="small" disabled={multiSelection.mode} onClick={onOpenLibrary}>Add</Button>} />

            }>
                <GProfiler id="primary-tree">
                    <MainObjectTree
                        project={project}
                        filter={filterFn}
                        multiSelection={multiSelection}
                        selectedObject={selectedObject}
                        tagCategory={selectedTagCategory || ""}
                        onNodeClick={onTreeItemClick}
                        onObjectSelectionToggle={onObjectMultiSelectionToggle}
                        onNodeActionClick={onTreeItemActionClick} />
                </GProfiler>
            </ScrollablePaneWithHeader>
        </Box>
    )
}
