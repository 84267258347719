import { isParentOrObject } from '@grenton/gm-common';
import { OutletConfigImpl, OutletImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { eventNodes } from './eventNodes';
import { outletNode } from './outletNode';
import { ProjectTreeItem, ProjectTreeItemObjectData, ProjectTreeItemData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { methodNodes } from './methodNodes';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { TreeContext } from './treeContext';

/**
 * for each object we
 * - if object is scriptable, render method nodes,
 * - render its outlets (except "parent" and "object" outlets)
 * -    if outlet has static references, resolve them and render as objectNode
 * -    if outlet has no static references, render them as outletNode
 * - if this object has an anonymous controller, render controller's "object" outlet as outletNode
 */

type Props = {
    object: ProjectObjectImpl;
    scriptOwner: ProjectObjectImpl;
    path: string[];
};

export function objectNode({ object, scriptOwner, path }: Props): ProjectTreeItem<ProjectTreeItemObjectData> {
    const protocol = object.api.api;

    //const controller = object.impl.type === 'script' ? null : ctx.objectResolver(`${object.uuid}${ANONYMOUS_CONTROLLER_SUFFIX}`);

    /*
     * path is super important here. it is a resolvable path from the CONTROLLER sitting in root of the branch,
     * to the node in this branch. So if we start branch from non-script object, path will always start from its anonymous controller and 'object' outlet.
     * any 'event' / 'method' node can simply take this path and use it as a script path w/o doing any computations.
     *
     * if we want to mask presence of anonymous controller (e.g. in breadcrumb), then we need to replace two first elements with managed object.
     */
    const itemId = path.join('/');

    const objectContext: TreeContext = {
        hasCode: (path: string[]) => scriptOwner.scriptHasContent(path.slice(1)),
    };

    const objectProtocolMethods =
        object.impl.type === 'script'
            ? methodNodes({
                  objectContext,
                  path,
                  protocol,
              })
            : [];

    const objectProtocolEvents =
        object.impl.type !== 'script'
            ? eventNodes({
                  objectContext,
                  parentId: itemId,
                  path,
                  protocol,
              })
            : [];

    const objectProtocolOutlets: ProjectTreeItem<ProjectTreeItemData>[] = Object.values(protocol.flat.outlets)
        .filter((outlet) => !isParentOrObject(outlet.id))
        .map((outlet) => {
            return [outletNode({ objectContext, parentId: itemId, path: [...path, outlet.id], outlet })];
        })
        .flat()
        .sort(sortTreeNodes); //; <- we need to preserve order of static refs (subobjects). TBD: we can sort other outlets

    // scriptableObject is the same object (if it is script type) or its anonymous controller (for non-script objects)
    // generally all non-script objects should have anonymous controller - they are added during import

    const item: ProjectTreeItem<ProjectTreeItemObjectData, ProjectTreeItemData> = {
        highlight: true,
        id: itemId,
        icon: 'unknown',
        label: '',
        sortKey: itemId,
        data: {
            type: ProjectTreeItemType.OBJECT,
            path,
            objectId: scriptOwner.uuid,
            rootTag: undefined,
            assignedToRootTag: true,
        },
        children: [...objectProtocolOutlets, ...objectProtocolMethods, ...objectProtocolEvents],
    };
    return item;
}

export function createIndexedOutletId(outlet: OutletImpl, outletConfig: OutletConfigImpl, index: number) {
    return outletConfig.staticRefs.length > 1 ? `${outlet.id}[${index + 1}]` : outlet.id;
}
