import { GTreeItemCheckboxState } from '@grenton/design-system';
import { ObjectMultiSelection } from '@grenton/gm/editor/utils';
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';

export type ItemSelection = { selectable: Record<string, GTreeItemCheckboxState>; selected: string[] };

const empty: ItemSelection = { selectable: {}, selected: [] };

export function itemSelection(
    items: ProjectTreeItem<ProjectTreeItemData>[],
    multiSelection: ObjectMultiSelection,
    selectedObject: string | null,
): ItemSelection {
    if (multiSelection.mode) {
        const selected: string[] = [];
        let selectable: Record<string, GTreeItemCheckboxState> = {};

        items.forEach((item) => {
            if (item.data.type === ProjectTreeItemType.OBJECT) {
                const s = multiSelection.objects[item.data.objectId];
                if (s !== undefined) {
                    selectable[item.id] = GTreeItemCheckboxState.ENABLED;
                    if (s) {
                        selected.push(item.id);
                    }
                } else {
                    selectable[item.id] = GTreeItemCheckboxState.DISABLED;
                }
            }

            if (item.children) {
                const r = itemSelection(item.children, multiSelection, selectedObject);
                selectable = { ...selectable, ...r.selectable };
                selected.push(...r.selected);
            }
        });

        return { selectable, selected };
    } else if (selectedObject) {
        const selected: string[] = [];

        items.forEach((item) => {
            if (item.data.type === ProjectTreeItemType.OBJECT) {
                if (selectedObject === item.data.objectId) {
                    selected.push(item.id);
                }
            }
            if (item.children) {
                const r = itemSelection(item.children, multiSelection, selectedObject);
                selected.push(...r.selected);
            }
        });

        return { selectable: {}, selected };
    } else return empty;
}
