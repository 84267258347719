import { eventNode } from './eventNode';
import { ProjectTreeItem, ProjectTreeItemDataExpand, ProjectTreeItemEventData } from '@grenton/gm/ui/components/projectComponentTree2';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { ScriptItemProps } from './scriptProps';
import { ObjectApi } from '@grenton/gm-common/src';
import { TreeContext } from './treeContext';

type Props = {
    objectContext: TreeContext;
    parentId: string;
    path: string[];

    // which API defines this event (it can be API of sourceObject or API of an outlet)
    protocol: ObjectApi;
};

export function eventNodes({
    path,
    // logical owner of event handlers (always scriptable)
    objectContext,
    // which API defines this event (it can be API of sourceObject or API of an outlet)
    protocol,
}: Props): ProjectTreeItem<ProjectTreeItemEventData | ProjectTreeItemDataExpand>[] {
    const nodes: ProjectTreeItem<ProjectTreeItemEventData | ProjectTreeItemDataExpand>[] = Object.values(protocol.flat.events)
        .map((event) => {
            const eventPath = [...path, event.id];
            return {
                name: event.label ?? event.id,
                primary: event.primary,
                path: eventPath,
                hasCode: objectContext.hasCode(eventPath),
            } satisfies ScriptItemProps;
        })
        // use expandable list only if protocol has some events marked as primary!
        //.filter(event => !expandableEventList || treeContext.fullyExpandedItems[moreId] || event.primary || event.hasCode)
        .map((event) => eventNode({ event }))
        .sort(sortTreeNodes);

    return nodes;
}
