import { useState } from 'react';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { SectionContent } from '@grenton/design-system';
import { InitialData } from './types';
import {
    useTheme,
    Stack,
    IconButton,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    MenuItem,
    Button,
    TextField,
} from '@mui/material';
import { List as ListIcon } from '@mui/icons-material';
import { ModalFullScreenMobile } from '@grenton/design-system';
import { useObservable } from '@grenton/utils';
import { EditProjectSettingsCommand, DeleteProjectCommand, SaveAsProjectCommand } from '../../../project';
import { useDispatcher, useProject } from '@grenton/gm/ui';
import { useService } from '@grenton/gm/providers';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ROOT } from '@grenton/gm/layout';
import { SaveAsDialog } from './SaveAsDialog';
import { DeleteProjectDialog } from './DeleteProjectDialog';

export function ProjectGeneralSetting(): ReactElement {
    const theme = useTheme();
    const [showAllRevisions, setShowAllRevisions] = useState(false);
    const project = useProject();
    const { repoClient, userHolder } = useService();
    const [firmwares] = useObservable(repoClient.firmwares);
    const dispatch = useDispatcher();
    const navigate = useNavigate();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        getValues,
    } = useForm<InitialData>({
        defaultValues: {
            label: project.label,
            firmware: project.firmware.version,
        },
    });

    const onSubmit = (updateArgs: InitialData) => {
        dispatch(new EditProjectSettingsCommand(updateArgs));
    };

    const onDelete = async () => {
        await dispatch(new DeleteProjectCommand(project.uuid));
        navigate(ROUTE_ROOT);
    };

    const onSaveAs = async (label:string) => {
        const user = userHolder.user.value!;
        await dispatch(new SaveAsProjectCommand(label, {id: user.sub, email: user.email}));
        handleSaveAsDialogClose();
    }

    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleSaveAsDialogClose = () => {
        setSaveAsDialogOpen(false);
    };

    const handleSaveAsDialogOpen = () => {
        setSaveAsDialogOpen(true);
    };

    return (
        <SectionContent>
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    margin: '0 auto',
                    maxWidth: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: theme.spacing(3),
                }}
            >
                <TextField
                    label="Name"
                    fullWidth={true}
                    {...register('label', { required: 'Label is required' })}
                    error={!!errors.label}
                    helperText={errors.label?.message}
                    onBlur={handleSubmit(onSubmit)}
                />
                <TextField 
                    value={project.uuid ?? ''} 
                    fullWidth={true} 
                    label="Project ID" 
                    variant="outlined" disabled />


                <TextField
                    label="Firmware"
                    select
                    fullWidth={true}
                    defaultValue={getValues('firmware')}
                    inputProps={register('firmware', {
                        required: 'Firmware is required',
                    })}
                    error={!!errors.firmware}
                    helperText={errors.firmware?.message}
                    onBlur={handleSubmit(onSubmit)}
                >
                    {firmwares?.map((f) => (
                        <MenuItem key={f.version} value={f.version}>
                            {f.version}
                        </MenuItem>
                    ))}
                </TextField>

                <Stack alignItems={'end'} position={'relative'} width={'100%'} flexDirection={'row'}>
                    <TextField 
                        fullWidth={true} 
                        value={project.revisions.head.tag ?? ''} 
                        label="Version" variant="outlined" disabled />
                    <IconButton sx={{ position: 'absolute', right: 0 }} onClick={() => setShowAllRevisions(true)}>
                        <ListIcon />
                    </IconButton>
                </Stack>

                <Button sx={{minWidth:150}} color='secondary' onClick={handleSaveAsDialogOpen}>Save As...</Button>
                <Button sx={{minWidth:150}} onClick={handleDeleteDialogOpen}>Delete project</Button>
                
                <DeleteProjectDialog open={deleteDialogOpen} projectLabel={project.label} onClose={handleDeleteDialogClose} onDelete={onDelete} />
                <SaveAsDialog open={saveAsDialogOpen} projectLabel={project.label} onClose={handleSaveAsDialogClose} onSaveAs={onSaveAs} />

            </form>

            <ModalFullScreenMobile isOpen={showAllRevisions} onClose={() => setShowAllRevisions(false)} title={'All revisions'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tag</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell sx={{ maxWidth: 200 }}>Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {project.revisions.all.map((rev) => {
                            return (
                                <TableRow key={rev.tag}>
                                    <TableCell>{rev.tag}</TableCell>
                                    <TableCell>{rev.author}</TableCell>
                                    <TableCell>{rev.ts.replace('T', ' ')}</TableCell>
                                    <TableCell>{rev.note}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </ModalFullScreenMobile>
        </SectionContent>
    );
}
