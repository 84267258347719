import { ProjectImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { ProjectTreeItem, ProjectTreeItemData } from '@grenton/gm/ui/components/projectComponentTree2';
import { objectNode } from './objectNode';
import { ANONYMOUS_CONTROLLER_SUFFIX, OUTLET_OBJECT } from '@grenton/gm-common';

type Props = {
    object: ProjectObjectImpl;
    project: ProjectImpl;
};

export function objectTreeModel({ object, project }: Props): ProjectTreeItem<ProjectTreeItemData>[] {
    let path: string[];
    let scriptOwner: ProjectObjectImpl | undefined;
    if (object.impl.type === 'script') {
        scriptOwner = object;
        path = [object.uuid];
    } else {
        const scriptOwnerId = `${object.uuid}${ANONYMOUS_CONTROLLER_SUFFIX}`;
        scriptOwner = project.getObjectById(scriptOwnerId);
        path = [scriptOwnerId, OUTLET_OBJECT];
    }

    return scriptOwner ? objectNode({ object, scriptOwner, path }).children ?? [] : [];
}
