import { ProjectObjectImpl } from '@grenton/gm-logic';
import type { ProjectTreeItem, ProjectTreeItemObjectData } from '@grenton/gm/ui/components/projectComponentTree2';
import { objectNode } from './objectNode';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { MainTreeContext } from './treeContext';

type Props = {
    treeContext: MainTreeContext;
    parentId: string;
    objects: ProjectObjectImpl[];
};

export function objectNodes({ parentId, objects, treeContext }: Props): ProjectTreeItem<ProjectTreeItemObjectData>[] {
    return objects
        .map((object) => {
            return objectNode({
                treeContext,
                parentId,
                object,
                asStaticReference: true,
            });
        })
        .sort(sortTreeNodes);
}
