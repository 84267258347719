import { fabric, notEmpty } from '@grenton/gm-common';
import { OutletImpl } from '@grenton/gm-logic';
import {
    ProjectTreeItem,
    ProjectTreeItemDataExpand,
    ProjectTreeItemDataWrapper,
    ProjectTreeItemEventData,
    ProjectTreeItemObjectData,
    ProjectTreeItemOutletData,
    ProjectTreeItemType,
} from '@grenton/gm/ui/components/projectComponentTree2';
import { concatTreeItemId } from './id';
import { objectNode } from './objectNode';
import { MainTreeContext } from './treeContext';

type Props = {
    treeContext: MainTreeContext;
    parentId: string;
    path: string[];
    outlet: OutletImpl;
    outletRefs?: fabric.OutletRefs;
};

export function outletNode({
    treeContext,
    parentId,
    path,
    outlet,
    outletRefs,
}: Props): ProjectTreeItem<
    ProjectTreeItemOutletData,
    ProjectTreeItemOutletData | ProjectTreeItemEventData | ProjectTreeItemDataExpand | ProjectTreeItemObjectData | ProjectTreeItemDataWrapper
> {
    const id = concatTreeItemId(parentId, outlet.id);

    const outletReferences =
        outletRefs?.refs
            .map(treeContext.objectResolver)
            .filter(notEmpty)
            .map((obj) =>
                objectNode({
                    treeContext,
                    object: obj,
                    parentId: id,
                    // for dynamic references, we hide protocol details.
                    // for statically referenced objects, that are visible only in this single place, we need to show protocols
                    //
                    // when showing dynamically referenced objects, always go with basic view,
                    // otherwise - inherit existing view
                    asStaticReference: Boolean(outletRefs.static),
                }),
            ) ?? [];

    return {
        id,
        label: outlet.name,
        sortKey: outlet.name,
        icon: 'outlet',
        data: {
            type: ProjectTreeItemType.OUTLET,
            path,
            outletId: outlet.id,
            refs: outletRefs?.refs.length || 0,
        },
        children: outletReferences,
    } satisfies ProjectTreeItem<
        ProjectTreeItemOutletData,
        ProjectTreeItemOutletData | ProjectTreeItemEventData | ProjectTreeItemDataExpand | ProjectTreeItemObjectData | ProjectTreeItemDataWrapper
    >;
}
