import { GDialogTitle, GDialogActions, ModuleLabel } from "@grenton/design-system";
import { GDialog } from "@grenton/design-system/src/theme2/components/GDialog";
import { DialogContent, DialogContentText, Button } from "@mui/material";
import { ObjectDeleteChain } from "./findObjectsToDelete";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";


type Props = {
    toDelete : ObjectDeleteChain[];
    onClose(): void;
    onDelete(): void;
}

export function DeleteObjectsDialog({toDelete, onClose, onDelete} : Props) {
    return <GDialog open={Boolean(toDelete.length)} onClose={onClose}>
    <GDialogTitle onClose={onClose}>Confirm deletion</GDialogTitle>
    <DialogContent>
        <DialogContentText>
            <SimpleTreeView selectedItems={null}>
                {toDelete
                    .filter(({linked})=>Boolean(linked))
                    .map(({linked})=>(
                    <TreeItem key={linked!.component.id} label={<ModuleLabel module={linked!.cmpRef}/>} itemId={linked!.component.id}>
                        {linked?.siblings.map(sibling=>(
                            <TreeItem key={sibling.object.uuid} itemId={sibling.object.uuid} label={sibling.object.label}>
                                {sibling.children.map(child=>(
                                    <TreeItem key={child.uuid} itemId={child.uuid} label={child.label}/>
                                ))}
                            </TreeItem>
                        ))}
                    </TreeItem>
                ))}
            </SimpleTreeView>
        </DialogContentText>
    </DialogContent>
    <GDialogActions 
            start={<Button color="white" onClick={onClose}>Cancel</Button>} 
            end={<Button color="primary" onClick={onDelete}>Delete</Button>}/>
    </GDialog>
}