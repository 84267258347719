import Splitter, { SplitDirection } from '@devbookhq/splitter'
import { Theme } from '@emotion/react';
import { Box, SxProps } from '@mui/material';
import { grentonColors } from '../colors';
import { useEffect, useRef, useState } from 'react';

export type GSplitterProps = {
    minWidths?: number[];
    minHeights?: number[];
    initialSizes?: number[];
    onResizeFinished?: (pairIdx: number, newSizes: number[]) => void;
    children?: React.ReactNode;
    direction: "horizontal" | "vertical";
}

const styles: SxProps<Theme> = {
    "& .__dbk__dragger": {
        position: "absolute",
        display: "block",
        background: `#fff !important`,
        border: `1px solid ${grentonColors.Line_Menu} !important`,
        zIndex: 1000,
    },
    "& .__dbk__gutter": {
        padding: "0 !important",
        background: `${grentonColors.Line_Menu} !important`,
        position: "relative",

        "&.Horizontal": {
            width: '1px',
            cursor: "col-resize",

            "& .__dbk__dragger": {
                width: '5px'
            }
        },
        "&.Vertical": {
            height: '1px',
            cursor: "row-resize",

            "& .__dbk__dragger": {
                height: '5px'
            }
        }
    }
}

export function GSplitter({ children, direction, minWidths, minHeights, initialSizes, onResizeFinished }: GSplitterProps) {

    // TODO we need a UI preference storage mechanism, (e.g. implemented as context)
    // to recover last size when component unmounts and mounts again
    const [sizes,setSizes] = useState(initialSizes)
    
    // splitter has a bug that it considers minWidths only during manual resize not when window is resized
    // we can fix this by setting minWidth on each panel manually after splitter is mounted
    const splitterRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (splitterRef.current) {
          if (minWidths) {
          splitterRef.current.querySelectorAll<HTMLElement>(':scope > .__dbk__container > .__dbk__child-wrapper' ).forEach((child, idx) => {
            const minWidth = minWidths[idx]
            if (minWidth) { // ignore undefined / '0'  
                child.style.minWidth = `${minWidth}px`
            }
            })
          }
        }
    }, [minWidths]);

    return <Box ref={splitterRef} sx={{ width: '100%', height: '100%', ...styles }}>
        <Splitter
            direction={direction === "horizontal" ? SplitDirection.Horizontal : SplitDirection.Vertical}
            minWidths={minWidths}
            minHeights={minHeights}
            initialSizes={sizes}
            onResizeFinished={(_pairIdx, sizes)=>{
                //TODO consider _pairIdx. for now we only use single splitter so it does not matter
                setSizes(sizes)
                onResizeFinished && onResizeFinished(_pairIdx, sizes)
            }}
        >
            {children}
        </Splitter>
    </Box>
}