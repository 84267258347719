import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { methodNode } from './methodNode';
import { ProjectTreeItem, ProjectTreeItemMethodData } from '@grenton/gm/ui/components/projectComponentTree2';

import { ObjectApiImpl } from '@grenton/gm-common/src';
import { ScriptItemProps } from '../mainObjectTreePane/components/mainObjectTree/utils/scriptProps';
import { TreeContext } from './treeContext';

type Props = {
    path: string[];
    // logical owner of event handlers (always scriptable)
    objectContext: TreeContext;
    // which API defines this event (it can be API of sourceObject or API of an outlet)
    protocol: ObjectApiImpl;
};

export function methodNodes({ path, objectContext, protocol }: Props): ProjectTreeItem<ProjectTreeItemMethodData>[] {
    const nodes: ProjectTreeItem<ProjectTreeItemMethodData>[] = Object.values(protocol.flat.methods)
        .map((method) => {
            const methodPath = [...path, method.id];
            return {
                name: method.label ?? method.id,
                scene: method.scene,
                path: methodPath,
                hasCode: objectContext.hasCode(methodPath),
            } satisfies ScriptItemProps;
        })
        .map((method) => methodNode({ method }))
        .sort(sortTreeNodes);

    return nodes;
}
