import { ProjectComponentTree2, ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemType, useExpandedItems } from "@grenton/gm/ui/components/projectComponentTree2";
import { Toolbar, Tooltip, Typography } from "@mui/material";
import { mainObjectTreeRenderer } from "../mainObjectTreePane/components/mainObjectTree/mainObjectTreeRenderer";
import { objectTreeModel } from "./objectTreeModel";
import { ObjectScriptRef, ProjectImpl, ProjectObjectImpl } from "@grenton/gm-logic/src";
import { ScrollablePaneWithHeader } from "@grenton/design-system";

type Props = {
  object?: ProjectObjectImpl
  project: ProjectImpl
  editedScriptRef?: ObjectScriptRef | null
  onPrimaryAction: (item:
    { type: 'event', data: ObjectScriptRef } |
    { type: 'method', data: ObjectScriptRef }
  ) => void
}

export function ObjectTreePane({ project, object, onPrimaryAction, editedScriptRef }: Props) {


  const { expandedItems, toggleExpandedItem } = useExpandedItems()
  const items = object ? objectTreeModel({ project, object }) : []

  const selectedItems = editedScriptRef ? [[editedScriptRef.objectId, ...editedScriptRef?.scriptRef.path.split('.')].join('/')] : []

  const _onItemClick = (item: ProjectTreeItem<ProjectTreeItemData>) => {
    const data = item.data
    switch (data.type) {
      case ProjectTreeItemType.EVENT: {
        onPrimaryAction({
          type: 'event',
          data: {
            objectId: data.path[0]!,
            scriptRef: {
              type: 'event',
              path: data.path.slice(1).join('.'),
            }
          }
        })
        break;
      }
      case ProjectTreeItemType.METHOD: {
        onPrimaryAction({
          type: 'method',
          data: {
            objectId: data.path[0]!,
            scriptRef: {
              type: 'method',
              path: data.path.slice(1).join('.')
            }
          }
        })
        //onSelectionChange(selection.withSingleObject(data.objectId))
        break
      }
    }
  }

  return (


    <ScrollablePaneWithHeader
      sx={{ background: '#fafafa' }}
      header={
        <Toolbar variant="dense" sx={{ minHeight: '56px' }}>
          <Tooltip title={object?.label} enterDelay={1000}>
          <Typography variant="m" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{object?.label}</Typography>
          </Tooltip>
        </Toolbar>
      }>

      <ProjectComponentTree2
        selectedItems={selectedItems}
        itemRenderer={mainObjectTreeRenderer}
        items={items}
        expandedItems={expandedItems}
        onItemExpansionToggle={toggleExpandedItem}
        onItemClick={(_, item) => _onItemClick(item)}
      />

    </ScrollablePaneWithHeader>
  );
}