import { GDialogActions } from "@grenton/design-system";
import { Dialog, Button, DialogContent } from "@mui/material";

type Props = {
    projectLabel: string;
    open: boolean
    onClose: () => void
    onDelete: () => void
}

export function DeleteProjectDialog({ open, projectLabel, onClose, onDelete }: Props) {

    return (
        <Dialog maxWidth="sm" open={open} onClose={onClose}>
            <DialogContent sx={{ padding: 4 }}>
                Are you sure you want to delete project '{projectLabel}'?
            </DialogContent>
            <GDialogActions
                start={<Button onClick={onClose} autoFocus>Cancel</Button>}
                end={<Button
                    onClick={() => onDelete()} color="primary">
                    Delete
                </Button>} />
        </Dialog>
    );
}