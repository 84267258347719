import { ObjectOutlet, OUTLET_PARENT } from '@grenton/gm-common';
import {
    ProjectTreeItem,
    ProjectTreeItemDataExpand,
    ProjectTreeItemDataWrapper,
    ProjectTreeItemEventData,
    ProjectTreeItemObjectData,
    ProjectTreeItemOutletData,
    ProjectTreeItemType,
} from '@grenton/gm/ui/components/projectComponentTree2';
import { eventNodes } from './eventNodes';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { TreeContext } from './treeContext';

type Props = {
    objectContext: TreeContext;
    parentId: string;
    path: string[];
    outlet: ObjectOutlet;
};

export function outletNode({
    objectContext,
    path,
    outlet,
}: Props): ProjectTreeItem<
    ProjectTreeItemOutletData,
    ProjectTreeItemOutletData | ProjectTreeItemEventData | ProjectTreeItemDataExpand | ProjectTreeItemObjectData | ProjectTreeItemDataWrapper
> {
    const id = path.join('/');

    // in main tree we're not interested in methods defined by outlet's protocol - they are not "scriptable" / "callable" from this tree
    // instead, we show them in target object's tree.

    const protocol = outlet.api;

    const protocolEvents: ProjectTreeItem<ProjectTreeItemEventData | ProjectTreeItemDataExpand>[] = eventNodes({
        objectContext,
        parentId: id,
        path,
        protocol,
    });

    const nestedOutlets = Object.values(protocol.flat.outlets)
        .filter((o) => o.id !== OUTLET_PARENT)
        .map((outlet) => outletNode({ objectContext, parentId: id, path: [...path, outlet.id], outlet }))
        .sort(sortTreeNodes);

    return {
        id,
        label: outlet.label ?? outlet.id,
        sortKey: outlet.label ?? outlet.id,
        icon: 'outlet',
        data: {
            type: ProjectTreeItemType.OUTLET,
            path,
            outletId: outlet.id,
            refs: undefined,
        },
        // render "onEvent" nodes only for scriptable objects, for others are read only
        // ...(outletRefs?.static ? outletReferences : outletReferences.length ? [outletReferencesWrapper]:[]),
        children: [...protocolEvents, ...nestedOutlets],
    } satisfies ProjectTreeItem<
        ProjectTreeItemOutletData,
        ProjectTreeItemOutletData | ProjectTreeItemEventData | ProjectTreeItemDataExpand | ProjectTreeItemObjectData | ProjectTreeItemDataWrapper
    >;
}
