import {ProjectImpl} from "@grenton/gm-logic";
import {ObjectID} from "@grenton/gm-common";
import {ProjectTreeItem, ProjectTreeItemData,ProjectComponentTree2, useExpandedItems} from "@grenton/gm/ui/components/projectComponentTree2";
import {SyntheticEvent} from "react";
import { targetObjectTreeModel } from "./targetObjectTreeModel";
import { targetObjectTreeRenderer } from "./targetObjectTreeRenderer";

type Props = {
    project: ProjectImpl,
    tagCategory: string,
    sourceObjectId?: ObjectID,
    actionsMode: boolean,
    onNodeClick: (node: ProjectTreeItem<ProjectTreeItemData>, e?: SyntheticEvent) => void
}

export function TargetObjectTree({project, tagCategory, sourceObjectId, actionsMode, onNodeClick}: Props) {

    const {expandedItems, toggleExpandedItem}= useExpandedItems()


    const onItemClick = (e:SyntheticEvent, item: ProjectTreeItem<ProjectTreeItemData>, data:any) => {
        if (data === 'action') {
            e.stopPropagation()
            return onNodeClick(item, e)
        }
    }
    return (
        <ProjectComponentTree2
            expandedItems={expandedItems}
            onItemExpansionToggle={toggleExpandedItem}
            selectedItems={[]}
            onItemClick={onItemClick}
            itemRenderer={targetObjectTreeRenderer}
            items={targetObjectTreeModel({project, sourceObjectId, actionsMode, tagCategory})}/>
    )
}

