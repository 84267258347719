import { ProjectTreeItem, ProjectTreeItemEventData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { ScriptItemProps } from './scriptProps';

type Props = {
    event: ScriptItemProps;
};

export function eventNode({ event }: Props): ProjectTreeItem<ProjectTreeItemEventData> {
    return {
        id: event.path.join('/'),
        label: event.name,
        icon: 'event',
        sortKey: event.name,
        data: {
            type: ProjectTreeItemType.EVENT as const,
            path: event.path,
            hasCode: event.hasCode,
        },
        children: [],
    };
}
