import { GDialogActions, GDialogTitle } from "@grenton/design-system";
import { Dialog, Button, DialogContent, TextField } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";

type Props = {
    projectLabel: string;
    open: boolean
    onClose: () => void
    onSaveAs: (label: string) => void
}

export function SaveAsDialog({ open, projectLabel, onClose, onSaveAs }: Props) {

    const [newLabel, setNewLabel] = useState<string>('');

    useEffect(() => {
        setNewLabel(`${projectLabel} copy ${format(new Date(), 'yyyy-MM-dd HH:mm')}`)
    }, [projectLabel, open])

    return (
        <Dialog maxWidth="sm" open={open} onClose={onClose}>
            <GDialogTitle onClose={onClose}>Save a copy of "{projectLabel}" as...</GDialogTitle>
            <DialogContent sx={{ p: 2, minWidth: 300 }}>
                <TextField
                    sx={{ mt: 2 }}
                    value={newLabel}
                    required
                    onChange={(e) => setNewLabel(e.target.value)}
                    fullWidth
                    label="Project name" />
            </DialogContent>
            <GDialogActions
                start={<Button onClick={onClose}>Cancel</Button>}
                end={<Button disabled={!newLabel}
                    onClick={() => onSaveAs(newLabel)} autoFocus color="primary">
                    Save
                </Button>} />
        </Dialog>
    );
}