import { Stack, Box, SxProps } from "@mui/material"

export function ScrollablePaneWithHeader({ header, footer, children, sx }: { 
    children: React.ReactNode, 
    header?: React.ReactNode,
    footer?: React.ReactNode,
    sx?: SxProps
}) {
return <Stack sx={{...sx, flexDirection: 'column', height: '100%' }}>
    {header}
    <Box sx={{ flexGrow: 1, overflow: 'auto', padding:0.5 }}>
        {children}
    </Box>
    {footer}
</Stack>
}