import type { ReactElement, SyntheticEvent } from "react";
import { TargetObjectTree } from './components';
import { Menu, MenuItem } from '@mui/material';
import { useCallback, useState } from 'react';
import { ProjectTreeItemType, type ProjectTreeItem, type ProjectTreeItemData } from '../../../ui/components/projectComponentTree2';
import { ObjectTreeFilter, ScrollablePaneWithHeader } from '@grenton/design-system';
import { ProjectImpl } from "@grenton/gm-logic";


type Props = {
    project: ProjectImpl,
    sourceObjectId?: string,
    actionsMode: boolean,
    onNodeClick: TargetObjectSelectorClickHandler
}

export type TargetObjectSelectorClickHandler = (data: ProjectTreeItemData, opts?: { set?: boolean }) => void

export function TargetObjectSelector({ project, sourceObjectId, actionsMode, onNodeClick }: Props): ReactElement {
    const [filterPattern, setFilterPattern] = useState<string | null>(null)
    const onNodeClick_memo = useCallback((node: ProjectTreeItem<ProjectTreeItemData>, opts?: { set?: boolean }) => onNodeClick(node.data, opts), [onNodeClick])

    const [optionRequired, setOptionRequired] = useState<{ node: ProjectTreeItem<ProjectTreeItemData>, readOnly: boolean, anchorEl: HTMLElement } | null>(null);

    const onNodeClickProxy = (node: ProjectTreeItem<ProjectTreeItemData>, e?: SyntheticEvent) => {
        if (node.data.type === ProjectTreeItemType.FEATURE) {
            setOptionRequired({ node, readOnly: node.data.readOnly === true, anchorEl: e!.currentTarget as HTMLElement })
        } else {
            onNodeClick_memo(node)
        }
    }

    const onOptionSelected = (set: boolean) => {
        if (optionRequired) {
            onNodeClick_memo(optionRequired.node, { set })
            setOptionRequired(null)
        }
    }

    const [selectedTagCategory, setSelectedTagCategory] = useState<string | null>(null)

    return (
        <>
            <Menu open={Boolean(optionRequired)}
                anchorEl={optionRequired?.anchorEl}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                anchorOrigin={{
                    vertical: -10,
                    horizontal: 60,
                }} onClose={() => setOptionRequired(null)}>

                <MenuItem onClick={() => onOptionSelected(false)}>Getter</MenuItem>
                <MenuItem disabled={optionRequired?.readOnly} onClick={() => onOptionSelected(true)}>Setter</MenuItem>
            </Menu>
            <ScrollablePaneWithHeader header={<ObjectTreeFilter
                filterPattern={filterPattern}
                onFilterPatternChange={setFilterPattern}
                tagCategories={project.tags.categories}
                onTagCategoryChange={setSelectedTagCategory}
                selectedTagCategory={selectedTagCategory} />}>
                <TargetObjectTree
                    tagCategory={selectedTagCategory || ""}
                    sourceObjectId={sourceObjectId}
                    project={project}
                    actionsMode={actionsMode}
                    onNodeClick={onNodeClickProxy} />
            </ScrollablePaneWithHeader>
        </>
    )
}
