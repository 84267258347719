import { isParentOrObject } from '@grenton/gm-common';
import { ProjectObjectImpl, OutletConfigImpl, OutletImpl } from '@grenton/gm-logic';
import { outletNode } from './outletNode';
import { ProjectTreeItem, ProjectTreeItemObjectData, ProjectTreeItemData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { concatTreeItemId } from './id';
import { MainTreeContext } from './treeContext';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';

/**
 * for each object we
 * - if object is scriptable, render method nodes,
 * - render its outlets (except "parent" and "object" outlets)
 * -    if outlet has static references, resolve them and render as objectNode
 * -    if outlet has no static references, render them as outletNode
 * - if this object has an anonymous controller, render controller's "object" outlet as outletNode
 */

type Props = {
    treeContext: MainTreeContext;
    parentId: string;
    object: ProjectObjectImpl;
    asStaticReference: boolean;
};

export function objectNode({ treeContext, parentId, object, asStaticReference }: Props): ProjectTreeItem<ProjectTreeItemObjectData> {
    const id = concatTreeItemId(parentId, object.uuid);
    const path = [object.uuid];
    const objectRefs = treeContext.references[object.uuid];

    const objectProtocolOutlets: ProjectTreeItem<ProjectTreeItemData>[] = asStaticReference
        ? Object.values(object.api.outlets)
              .filter((outlet) => !isParentOrObject(outlet.id))
              .map((outlet) => {
                  const outletRefs = objectRefs?.[outlet.id];
                  return [outletNode({ treeContext, parentId: id, path: [...path, outlet.id], outlet, outletRefs })];
              })
              .flat()
              .sort(sortTreeNodes)
        : []; // <- we need to preserve order of static refs (subobjects). TBD: we can sort other outlets

    const item: ProjectTreeItem<ProjectTreeItemObjectData, ProjectTreeItemData> = {
        highlight: true,
        id,
        icon: treeContext.iconResolver(object) || 'unknown',
        label: asStaticReference && object.label.includes('.') ? `.${object.label.split('.').at(-1)}` : object.label,
        sortKey: object.label,
        rootClassName: 'object-node',
        data: {
            type: ProjectTreeItemType.OBJECT,
            path: [object.uuid], // FIXME! absolute path to object or via its anonymous controller?
            objectId: object.uuid,
            rootTag: treeContext.rootTag,
            assignedToRootTag: !treeContext.rootTag || object.hasTag(treeContext.rootTag),
        },
        children: [...objectProtocolOutlets],
    };
    return item;
}

export function createIndexedOutletId(outlet: OutletImpl, outletConfig: OutletConfigImpl, index: number) {
    return outletConfig.staticRefs.length > 1 ? `${outlet.id}[${index + 1}]` : outlet.id;
}
