import {ExpandMore, ExpandLess, MoreHoriz} from "@mui/icons-material";
import { ProjectTreeItemType, ProjectTreeItemData, ProjectTreeItem } from "@grenton/gm/ui/components/projectComponentTree2";
import { GTreeItemRendererProps, ModuleLabel, TagLabel } from "@grenton/design-system";
import { IconConstructor, icons } from "@grenton/gm/ui/icons";
import React from "react";
import { ActionIconButton, CenterTreeItem, ObjectTreeItemContent, ObjectTreeItemRoot } from "@grenton/gm/ui/components/objectTreeItem";
import { Box } from "@mui/material";

function RefCount({refs}:{refs:number}) {
    //return <Button size="small" sx={{padding:0}}>{refs}</Button>
    return <Box sx={{fontSize:'0.7rem',background:'#888888aa',color:'#fff',py:0.1,px:0.4,mr:1,minWidth:'2em',borderRadius:'1em',display:'flex',justifyContent:'center'}}>{refs}</Box>
}


export function mainObjectTreeRendererDebug({item}: GTreeItemRendererProps<ProjectTreeItem<ProjectTreeItemData>>): JSX.Element {
    return <ObjectTreeItemContent label={`<${item.label}> ${(item.data as any).path?.join('/')||''}`}/>
}

export function mainObjectTreeRenderer({item, onClick}: GTreeItemRendererProps<ProjectTreeItem<ProjectTreeItemData>>): JSX.Element {
    
    function content() {
        const data = item.data
        
        switch (data.type) {
            case ProjectTreeItemType.MODULE:
                return <ObjectTreeItemContent label={<ModuleLabel module={data.module}/>} hint={data.module.id}/>
            case ProjectTreeItemType.SPACE:
                return <CenterTreeItem align="start"><TagLabel label={data.tag} blank/></CenterTreeItem>
            case ProjectTreeItemType.MORE:
                return <CenterTreeItem align="center"><ExpandMore sx={{fontSize: 'inherit'}}/></CenterTreeItem>
            case ProjectTreeItemType.LESS:
                return <CenterTreeItem align="center"><ExpandLess sx={{fontSize: 'inherit'}}/></CenterTreeItem>
            case ProjectTreeItemType.METHOD:
            case ProjectTreeItemType.EVENT: {
                const label = /*data.hasCode ? `\u2022 ${item.label}` :*/ item.label
                return <ObjectTreeItemContent label={label} emphasis={data.hasCode} 
                    greyedOut={!data.hasCode} 
                    action={undefined /*<ActionIconButton icon={<MoreHoriz/>} onClick={e=>onClick(e,'action')}/>*/}/>
            }
            case ProjectTreeItemType.OUTLET:
                return <ObjectTreeItemContent label={item.label} 
                    hint={data.refs !== undefined ? <RefCount refs={data.refs}/> : undefined} 
                    action={undefined /*<ActionIconButton icon={<MoreHoriz/>} onClick={e=>onClick(e,'action')}/>*/}/>
            case ProjectTreeItemType.OBJECT:
                return <ObjectTreeItemContent label={item.label} greyedOut={!data.assignedToRootTag} action={<ActionIconButton icon={<MoreHoriz/>} onClick={e=>onClick(e,'action')}/>}/>
            case ProjectTreeItemType.OBJECT_REF_WRAPPER: 
                return <ObjectTreeItemContent label={item.label}/>// <Typography variant="s" sx={{marginLeft:'1rem'}}>objects</Typography>
            default:
                return <ObjectTreeItemContent label={item.label}/>
        }
    }

    function iconOpacity() {
        switch (item.data.type) {
            case ProjectTreeItemType.METHOD:
            case ProjectTreeItemType.EVENT: {
                return item.data.hasCode ? 1 : 0.7
            }
            default:
                return 1
        }
    }


    let icon:React.ReactNode = null
    if (item.icon) {
        const ResolvedIcon:IconConstructor = icons[item.icon]
        icon = <ResolvedIcon fontSize="inherit" sx={{fontSize:'1.2rem', opacity:iconOpacity()}}/>
    }

    return <ObjectTreeItemRoot icon={icon} onClick={onClick}>{content()}</ObjectTreeItemRoot>
}

