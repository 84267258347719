import { Route } from "react-router-dom"
import { ProjectGeneralSetting } from "../general/ui"
import { ProjectLanguages } from "../i18n/ui"
import { ProjectTags } from "../tags/ui"
import { ProjectUsers } from "../users/ui"
import { SettingsMenu } from "./SettingsMenu"
import { withProject } from "@grenton/gm/providers/withProject"

export const ROUTE_PROJECT = '/project';


export function settingRoutes() {
    return <Route path={ROUTE_PROJECT} element={withProject(<SettingsMenu />)}>
        <Route index={true} element={withProject(<ProjectGeneralSetting />)} />
        <Route path="languages" element={withProject(<ProjectLanguages />)} />
        <Route path="tags" element={withProject(<ProjectTags />)} />
        <Route path="users" element={withProject(<ProjectUsers />)} />
    </Route>
}