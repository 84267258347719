import { grentonColors } from "@grenton/design-system";
import { styled, Box, List as MuiList, ListItemButton } from "@mui/material";


export const SettingMenuList = styled(Box)(({  }) => ({
    height: "100%",
    display: 'grid',
    gridTemplateColumns: "15rem 1fr",
    background:'#fafafa'
}));


export const List = styled(MuiList)(({  }) => ({
    width: "100%",
    borderRight: `1px solid ${grentonColors.Line_Menu}`
}));

export const Button = styled(ListItemButton)(({ theme }) => ({
    width: "100%",
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));
