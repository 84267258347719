import { Clear, LocalOfferOutlined, Search, Star, StarOutline } from "@mui/icons-material"
import { ButtonBase, Icon, IconButton, InputAdornment, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { TagLabel } from "../../molecules"
import { PropsWithChildren, useState } from "react"
import { grentonColors, icons } from "../../../.."

type ObjectTreeFilterProps = {
    filterPattern : string|null
    onFilterPatternChange: (filterPattern: string) => void
    tagCategories : { name: string, color: string }[]
    selectedTagCategory: string|null
    onTagCategoryChange: (tagCategory: string|null) => void
}

function EmptyTagLabel({children,color}:PropsWithChildren<{color?:string}>) {
    return <TagLabel label={
            <Typography variant="m" sx={{color:color||grentonColors.Font_Placeholder, height:'1.5em',display:'flex',alignItems:'center'}}>{children}</Typography>
        }
        style={{minWidth:'auto',color:grentonColors.Font_Placeholder,borderColor:'transparent'}}>
        </TagLabel>
}

function usePopupMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpened = Boolean(anchorEl);
    
    const open = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };

    return {
        anchorEl, isOpened, open, close
    }
}

const favGroups = [
    {name:'a',color:'#05A80B'},
    {name:'b',color:'#00C2FF'},
    {name:'c',color:'#0500FF'},
    {name:'d',color:'#8F00FF'},
    {name:'e',color:'#FF0000'},
    {name:'f',color:'#FFD600'}
];

export function ObjectTreeFilter({filterPattern, onFilterPatternChange,selectedTagCategory,onTagCategoryChange,tagCategories}: ObjectTreeFilterProps) {

    const tagMenu = usePopupMenu()
    const starMenu = usePopupMenu()

    const [favGroup,setFavGroup] = useState<string|null>(null)

    const onClear = () => {
        if (filterPattern) {
            onFilterPatternChange('')
        } 
        else if (favGroup) {
            onSelectStar(null)
        }
        else if (selectedTagCategory) {
            onTagCategoryChange(null)
        }
    }

    const onSelectCategory = (category: string|null) => {
        onTagCategoryChange(category)
        tagMenu.close()
    }

    const onSelectStar = (value: string|null) => {
        setFavGroup(value)
        starMenu.close()
    }

    const selectedTagCat = tagCategories.find(cat => cat.name === selectedTagCategory)

    return <Stack sx={{flexDirection:'row',gap:0,padding:1,paddingRight:0.5, alignItems:'center'}}>
        <ButtonBase onClick={tagMenu.open} sx={{minWidth:'4em',borderRadius:'1em'}}>
        { selectedTagCat ? <TagLabel label={selectedTagCat.name} color={selectedTagCat.color}/> : 
            <EmptyTagLabel><LocalOfferOutlined/></EmptyTagLabel> 
        }
        </ButtonBase>

        <ButtonBase onClick={starMenu.open} sx={{borderRadius:'1em'}}>
        <EmptyTagLabel color={favGroups.find(f=>f.name===favGroup)?.color}>{favGroup ? <Star/> : <StarOutline/>}</EmptyTagLabel>
        </ButtonBase>

        <TextField variant="standard" 
          sx={{flexGrow:1,paddingLeft:1}}
          value={filterPattern||''}
          onChange={(e)=>onFilterPatternChange(e.target.value)}
          placeholder="Filter"
          InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{fontSize:'1.4em'}}>
              <Search  fontSize="inherit" color="inherit"/>
            </InputAdornment>
          )
        }}/>

        <IconButton disabled={!filterPattern && !selectedTagCat && !favGroup} onClick={onClear}><Clear/></IconButton>


        <Menu
            anchorEl={tagMenu.anchorEl}
            open={tagMenu.isOpened}
            onClose={tagMenu.close}
            MenuListProps={{
            }}
        >
            <Typography variant="s" sx={{display:'block',color:grentonColors.Font_Placeholder,px:2,py:1}}>Group by tag</Typography>

  
            {tagCategories.map(cat => (
                <MenuItem key={cat.name} onClick={()=>onSelectCategory(cat.name)}><TagLabel color={cat.color} label={cat.name}  checked={selectedTagCategory===cat.name || undefined}/></MenuItem>
            ))}
            <MenuItem key={""} onClick={()=>onSelectCategory(null)} sx={{justifyContent:'center',my:1}}>
            <Icon fontSize="small"><icons.Close/></Icon>
            </MenuItem>
        </Menu>

        <Menu
            anchorEl={starMenu.anchorEl}
            open={starMenu.isOpened}
            onClose={starMenu.close}
            MenuListProps={{
            }}
        >
            <Typography variant="s" sx={{display:'block',color:grentonColors.Font_Placeholder,px:2,py:1}}>Filter by star</Typography>


            {favGroups.map(fav => (
                <MenuItem key={fav.name} sx={{justifyContent:'center'}} onClick={()=>onSelectStar(fav.name)}>
                    <Icon sx={{color:fav.color}}><StarOutline/></Icon>
                </MenuItem>
            ))}
                        <MenuItem key={""} onClick={()=>onSelectStar(null)} sx={{justifyContent:'center',my:1}}>
            <Icon fontSize="small"><icons.Close/></Icon>
            </MenuItem>
            
        </Menu>
    </Stack>
}