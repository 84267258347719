import { ProjectTreeItemMethodData, ProjectTreeItem, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { ScriptItemProps } from './scriptProps';

type Props = {
    method: ScriptItemProps;
};

export function methodNode({ method }: Props): ProjectTreeItem<ProjectTreeItemMethodData> {
    return {
        id: method.path.join('/'),
        label: method.name,
        icon: method.scene ? 'scene' : 'method',
        sortKey: method.name,
        data: {
            type: ProjectTreeItemType.METHOD,
            path: method.path,
            scene: Boolean(method.scene),
            hasCode: method.hasCode,
        },
        children: [],
    };
}
