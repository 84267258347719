import { Box, Stack, Tab } from "@mui/material";
import React, { useMemo } from "react";
import { ObjectEditForm, ObjectEventForm, ObjectFeatureForm, ObjectMethodForm, ObjectOutletForm, ProjectImpl, ProjectObjectImpl, candidateProvider } from "@grenton/gm-logic";
import { ValidatorResult } from "../../../../ui/form-validation";

import { schema, evaluateOutletSelector, notEmpty } from "@grenton/gm-common";
import { findCustomObjectControl } from './utils';
import { useProject } from "@grenton/gm/ui";
import { ObjectPropertiesFormMeta } from "./types";
import { GTabLabel, GTabPanel, GTabs } from "@grenton/design-system";
import { RuntimeData } from "./runtime";
import { ObjectProperties } from "./objectProperties";
import { newApiItemId } from "@grenton/gm-logic";
import { FeatureList, FeatureEdit, FeatureEditFormMeta } from "./features";
import { MethodList, MethodEdit, MethodEditFormMeta, methodFormValidator } from "./methods";
import { EventList, EventEdit, EventEditFormMeta, eventEditFormValidator } from "./events";
import { OutletList, OutletEdit, OutletPropertiesFormMeta } from "./outlets";

export type ObjectEditProps = {
    meta: ObjectPropertiesFormMeta;
    form: ObjectEditForm;
    errors: ValidatorResult;
    onChange: (form: ObjectEditForm, modified: boolean) => void;
};


export function findOutletObjects(p: ProjectImpl, ownerTags: string[], form: ObjectOutletForm): ProjectObjectImpl[] {
    const objectProvider = candidateProvider(p)

    return p && form.apiRef ? evaluateOutletSelector({
        objectProvider, ownerTags, outletProtocol:form.apiRef, selector:form.config.selector}
    ).map(p.objectResolver).filter(notEmpty) : [];
}

export function ObjectEditPane({ meta, form, errors, onChange }: ObjectEditProps) {

    const project = useProject()

    const customControl = useMemo(() => findCustomObjectControl(meta), [meta])

    const applyChanges = (changes: Partial<ObjectEditForm>, modified = true) => {
        onChange({ ...form, ...changes }, modified)
    }

    function hasLabel(label: string) {
        return Boolean(Object.values(form.apiItems.features).find(i => (i.spec.label || i.spec.id) === label) ||
            Object.values(form.apiItems.methods).find(i => (i.spec.label || i.spec.id) === label) ||
            Object.values(form.apiItems.events).find(i => (i.spec.label || i.spec.id) === label) ||
            Object.values(form.apiItems.outlets).find(i => (i.spec.label || i.spec.id) === label))
    }

    const editedFeatureInstance = form.editedFeature ? form.apiItems.features[form.editedFeature] : null
    const editedFeatureMeta: FeatureEditFormMeta | null = useMemo(() => (
        { types: schema.PropertyValueTypes.filter(t => t !== schema.PROPERTY_TYPE_VOID).map(t => (t)) }), [editedFeatureInstance])

    const editedMethodInstance = form.editedMethod ? form.apiItems.methods[form.editedMethod] : null
    const editedMethodMeta: MethodEditFormMeta | null = useMemo(() => (editedMethodInstance ? {
        validator: methodFormValidator(label => (label !== (editedMethodInstance.spec.label || editedMethodInstance.spec.id) && !!hasLabel(label))),
        returnTypes: schema.PropertyValueTypes.map(t => (t)),
        paramTypes: schema.PropertyValueTypes.filter(t => t !== schema.PROPERTY_TYPE_VOID).map(t => (t)),
        readOnly: editedMethodInstance?.inherited === true
    } : null), [editedMethodInstance])

    const editedEventInstance = form.editedEvent ? form.apiItems.events[form.editedEvent] : null
    const editedEventMeta: EventEditFormMeta | null = useMemo(() => (editedEventInstance ? {
        validator: eventEditFormValidator(label => (label !== (editedEventInstance.spec.label || editedEventInstance.spec.id) && !!hasLabel(label))),
    }
        : null), [editedEventInstance])


    const editedOutletInstance = form.editedOutlet ? form.apiItems.outlets[form.editedOutlet] : null
    const editedOutletMeta: OutletPropertiesFormMeta | null = useMemo(() => (editedOutletInstance ? {
        readOnly: !editedOutletInstance.editable,
        project,
        validator: () => ValidatorResult.validResult,
        objectResolver: project.objectResolver,
        types: [...project.firmware.apis].map(api => api.id).toSorted(),
        selectionResolver: (oform) => findOutletObjects(project, form.tags, oform)
    }
        : null), [editedOutletInstance, project, form.tags])

    function onStartEditFeature(featureId?: string) {
        let feature: ObjectFeatureForm
        if (!featureId) {
            featureId = newApiItemId()
            feature = {
                inherited: false,
                editable: true,
                spec: {
                    id: featureId,
                    label: "",
                    type: "string"
                },
                config: {
                }
            }
            applyChanges({ editedFeature: featureId, apiItems: { ...form.apiItems, features: { ...form.apiItems.features, [featureId]: feature } } })
        } else if (featureId !== form.editedFeature) {
            applyChanges({ editedFeature: featureId }, false)
        } else {
            onStopEditFeature()
        }
    }

    function onStopEditFeature() {
        applyChanges({ editedFeature: undefined }, false)
    }

    function onStartEditMethod(methodId?: string) {
        let method: ObjectMethodForm
        if (!methodId) {
            methodId = newApiItemId()
            method = {
                inherited: false,
                editable: true,
                spec: {
                    id: methodId,
                    label: ""
                }
            }
            applyChanges({ editedMethod: methodId, apiItems: { ...form.apiItems, methods: { ...form.apiItems.methods, [methodId]: method } } })
        } else if (methodId !== form.editedMethod) {
            applyChanges({ editedMethod: methodId }, false)
        } else {
            
        }
    }

    function onStopEditMethod() {
        applyChanges({ editedMethod: undefined }, false)
    }

    function onStartEditEvent(eventId?: string) {
        let event: ObjectEventForm
        if (!eventId) {
            eventId = newApiItemId()
            event = {
                inherited: false,
                editable: true,
                spec: {
                    id: eventId,
                    label: ""
                }
            }
            applyChanges({ editedEvent: eventId, apiItems: { ...form.apiItems, events: { ...form.apiItems.events, [eventId]: event } } })
        } else if (eventId !== form.editedEvent) {
            applyChanges({ editedEvent: eventId }, false)
        } else {
            applyChanges({ editedEvent: undefined }, false)
        }
    }

    function onStopEditEvent() {
        applyChanges({ editedEvent: undefined }, false)
    }

    function onStartEditOutlet(outletId?: string) {
        let outlet: ObjectOutletForm
        if (!outletId) {
            outletId = newApiItemId()
            outlet = {
                inherited: false,
                editable: true,
                apiRef: null,
                spec: {
                    id: outletId,
                    label: ""
                },
                config: {
                    editable: true,
                    selector: {}
                }
            }
            applyChanges({ editedOutlet: outletId, apiItems: { ...form.apiItems, outlets: { ...form.apiItems.outlets, [outletId]: outlet } } })
        }
        else if (outletId !== form.editedOutlet) {
            applyChanges({ editedOutlet: outletId }, false)
        } else {
            onStopEditOutlet()
        }
    }

    function onStopEditOutlet() {
        applyChanges({ editedOutlet: undefined }, false)
    }

    function onDeleteFeature(featureId: string) {
        const features = { ...form.apiItems.features }
        delete features[featureId]
        applyChanges({ apiItems: { ...form.apiItems, features } })
    }

    function onDeleteEvent(eventId: string) {
        const events = { ...form.apiItems.events }
        delete events[eventId]
        applyChanges({ apiItems: { ...form.apiItems, events } })
    }

    function onDeleteMethod(methodId: string) {
        const methods = { ...form.apiItems.methods }
        delete methods[methodId]
        applyChanges({ apiItems: { ...form.apiItems, methods } })
    }

    function onDeleteOutlet(outletId: string) {
        const outlets = { ...form.apiItems.outlets }
        delete outlets[outletId]
        applyChanges({ apiItems: { ...form.apiItems, outlets } })
    }

    function onChangeFeature(feature: ObjectFeatureForm) {
        applyChanges({ apiItems: { ...form.apiItems, features: { ...form.apiItems.features, [feature.spec.id]: feature } } })
    }

    function onChangeMethod(method: ObjectMethodForm) {
        applyChanges({ apiItems: { ...form.apiItems, methods: { ...form.apiItems.methods, [method.spec.id]: method } } })
    }

    function onChangeEvent(event: ObjectEventForm) {
        applyChanges({ apiItems: { ...form.apiItems, events: { ...form.apiItems.events, [event.spec.id]: event } } })
    }

    function onChangeOutlet(outlet: ObjectOutletForm) {
        applyChanges({ apiItems: { ...form.apiItems, outlets: { ...form.apiItems.outlets, [outlet.spec.id]: outlet } } })
    }

    function onDefaultValueChange(featureId: string, value: schema.PropertyValue) {
        applyChanges({...form, apiItems: {
            ...form.apiItems,
            features: {
                ...form.apiItems.features,
                [featureId]: {
                    ...form.apiItems.features[featureId]!,
                    config: {
                        // TODO1
                        value
                    }
                }
            }
        }})
    }

    return (
        <Stack sx={{ flexGrow: 2 }}>

            <GTabs value={form.selectedTab} onChange={(_, selectedTab) => onChange({ ...form, selectedTab }, false)} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                <Tab value='general' label={<GTabLabel>Properties</GTabLabel>} />
                {customControl ? <Tab value='widget' label={<GTabLabel>Control</GTabLabel>} /> : null}
                {form.id.length === 1 ? <Tab value='runtime' label={<GTabLabel>Runtime</GTabLabel>} /> : null}
                <Tab value='features' label={<GTabLabel>Features</GTabLabel>} />
                <Tab value='methods' label={<GTabLabel>Methods</GTabLabel>} />
                <Tab value='events' label={<GTabLabel>Events</GTabLabel>} />
                <Tab value='outlets' label={<GTabLabel>Outlets</GTabLabel>} />
            </GTabs>

            <Box sx={{ flexGrow: 2, overflow: 'auto' }}>
                <GTabPanel fullHeight={true} selected={form.selectedTab} value='general' sx={{ flexDirection: 'row' }}>
                    <ObjectProperties form={form} meta={meta} errors={errors} onChange={(form)=>onChange(form,true)}/>
                </GTabPanel>
                {customControl && <GTabPanel fullHeight={true} selected={form.selectedTab} value='widget'>
                    {React.createElement(customControl, {
                        form, meta, onUpdate: (_form) => {
                        }
                    })}
                </GTabPanel>}
                { form.id.length === 1 && <GTabPanel fullHeight={true} selected={form.selectedTab} value='runtime'>
                    <RuntimeData
                        features={form.apiItems.features}
                        methods={form.apiItems.methods}
                    />
                </GTabPanel> }
                <GTabPanel fullHeight={true} selected={form.selectedTab} value='features'>
                    <FeatureList
                        onDefaultValueChange={onDefaultValueChange}
                        onAdd={meta.protocol.editable ? onStartEditFeature : undefined}
                        onEdit={meta.protocol.editable ? onStartEditFeature : undefined}
                        editedItemId={editedFeatureInstance?.spec.id}
                        items={form.apiItems.features}
                        onDelete={onDeleteFeature}
                    >
                        {editedFeatureInstance && editedFeatureMeta &&
                            <FeatureEdit
                                form={editedFeatureInstance}
                                meta={editedFeatureMeta}
                                errors={ValidatorResult.validResult} //TODO
                                onClose={onStopEditFeature}
                                onChange={onChangeFeature} />
                        }
                    </FeatureList>
                </GTabPanel>
                <GTabPanel fullHeight={true} selected={form.selectedTab} value='methods'>
                    <MethodList
                        items={form.apiItems.methods}
                        editedItemId={editedMethodInstance?.spec.id}
                        onAdd={meta.protocol.editable ? onStartEditMethod : undefined}
                        onEdit={meta.protocol.editable ? onStartEditMethod : undefined}
                        onDelete={onDeleteMethod}
                    >
                        {editedMethodInstance && editedMethodMeta &&
                            <MethodEdit
                                meta={editedMethodMeta}
                                form={editedMethodInstance}
                                errors={ValidatorResult.validResult}    // TODO
                                onChange={onChangeMethod}
                                onClose={onStopEditMethod}
                            />}
                    </MethodList>
                </GTabPanel>
                <GTabPanel fullHeight={true} selected={form.selectedTab} value='events'>
                    <EventList
                        items={form.apiItems.events}
                        editedItemId={editedEventInstance?.spec.id}
                        onAdd={meta.protocol.editable ? onStartEditEvent : undefined}
                        onEdit={meta.protocol.editable ? onStartEditEvent : undefined}
                        onDelete={onDeleteEvent}
                    >
                        {editedEventInstance && editedEventMeta &&
                            <EventEdit
                                meta={editedEventMeta}
                                form={editedEventInstance}
                                errors={ValidatorResult.validResult}
                                onChange={onChangeEvent}
                                onClose={onStopEditEvent}
                                />}
                    </EventList>
                </GTabPanel>
                <GTabPanel fullHeight={true} selected={form.selectedTab} value='outlets'>
                    <OutletList
                        items={form.apiItems.outlets}
                        editedItemId={editedOutletInstance?.spec.id}
                        onAdd={meta.protocol.editable ? onStartEditOutlet : undefined}
                        onEdit={meta.protocol.editable ? onStartEditOutlet : undefined}
                        onDelete={onDeleteOutlet}
                    >
                        {editedOutletInstance && editedOutletMeta &&
                            <OutletEdit errors={ValidatorResult.validResult}
                                form={editedOutletInstance}
                                meta={editedOutletMeta}
                                onChange={onChangeOutlet}
                                onClose={onStopEditOutlet}
                                />}
                    </OutletList>
                </GTabPanel>
            </Box>
        </Stack>
    )
}
