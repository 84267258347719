import { Showcase } from '@grenton/design-system';
import { AppEditor } from '@grenton/gm/app/AppEditor';
import { useGrentonAuth } from '@grenton/gm/auth';
import { HardwarePage } from '@grenton/gm/hardware/HardwarePage';
import { ROUTE_APP, ROUTE_EDITOR, ROUTE_HARDWARE, ROUTE_PREPARATION, ROUTE_ROOT, ROUTE_UI, ROUTE_USER } from './routes';
import NewProject from '@grenton/gm/new/NewProject';
import { PreparationPage } from '@grenton/gm/preparation/ui';
import { useService } from '@grenton/gm/providers';
import { UserScreen } from '@grenton/gm/user/ui';
import { Button, CircularProgress, Stack, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import type { ReactElement } from 'react';
import { useContext, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ExportMqttDialog, ExportPreviewDialog, MainMenu, MenuDrawer, TopAppBar } from './components';
import { ExportViaRESTDialog } from './components/exportRestDialog/ExportViaRESTDialog';
import type { UploadType } from "./types";
import { NotificationSnackbar } from '@grenton/gm/notifications/NotificationSnackbar';
import { NotificationDialog } from '@grenton/gm/notifications';
import { CertificateInstallWizard } from '@grenton/gm/hardware/certificate/CertificateInstallWizard';
import { ProjectContext } from '@grenton/gm/providers/ProjectContext';
import { EditorPageWrapper } from '@grenton/gm/editor/EditorPageWrapper';
import { withProject } from '@grenton/gm/providers/withProject';
import { settingRoutes } from '@grenton/gm/settings/menu/routes';

const drawerWidth: number = 240;

export function LayoutMain(): ReactElement {
    const location = useLocation()
    const navigate = useNavigate()
    const {configurationUploader,projectExporter} = useService()
    const [menuDrawerOpen, setMenuDrawerOpen] = useState(false)
    const [notificationsOpen, setNotificationsOpen] = useState(false)

    const {user,isAuthenticated,isLoading, loginWithRedirect,error} = useGrentonAuth()
    const project = useContext(ProjectContext)
    const projectLoaded = Boolean(project)

    const [exportPreviewDialog, setExportPreviewDialog] = useState(false)
    const [exportMqttDialog, setExportMqttDialog] = useState(false)
    const [exportHttpDialog, setExportHttpDialog] = useState(false)

    const toggleDrawer = () => {
        setMenuDrawerOpen(!menuDrawerOpen)
    }

    const handleLogin = async () => {
        await loginWithRedirect(ROUTE_ROOT)
    };

    function onUpload(type: UploadType): void {
        switch (type) {
            case 'preview' : {
                setExportPreviewDialog(true)
                break;
            }
            case 'file' : {
                const link = document.createElement("a");
                projectExporter.exportProject().then(data => {
                    const txt = JSON.stringify(data.project, null, 2);
                    const file = new Blob([txt], {type: 'text/plain'});
                    link.href = URL.createObjectURL(file);
                    link.download = "grenton-configuration.json";
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                break;
            }
            case 'mqtt' : {
                setExportMqttDialog(true);
                break
            }
            case 'http' : {
                setExportHttpDialog(true);
                break
            }
        }
    }

    const withToolbar = true
    return (
        <Stack sx={{height: '100vh',position:'relative',overflow:'hidden'}} direction={"row"}>
            <NotificationSnackbar/>
            <NotificationDialog open={notificationsOpen} onClose={()=>setNotificationsOpen(false)}/>
            <CertificateInstallWizard/>
            <ExportPreviewDialog
                open={exportPreviewDialog}
                onClose={() => setExportPreviewDialog(false)}
            />
            <ExportMqttDialog
                open={exportMqttDialog}
                onClose={() => setExportMqttDialog(false)}
            />
            <ExportViaRESTDialog
                open={exportHttpDialog} onClose={() => setExportHttpDialog(false)}
                onApply={() => configurationUploader.uploadProject()}
            />
            {withToolbar && <TopAppBar
                projectLoaded={projectLoaded}
                projectTitle={project?.label || project?.uuid || ''}
                projectModified={false}
                open={menuDrawerOpen}
                toggleDrawer={toggleDrawer}
                onUpload={onUpload}
                drawerWidth={drawerWidth}
            /> }
            {user ?
                <MenuDrawer open={menuDrawerOpen} drawerWidth={drawerWidth}>
                    <MainMenu
                        withToolbar={withToolbar}
                        toggleDrawer={toggleDrawer} 
                        open={menuDrawerOpen}
                        user={user}
                        onUpload={onUpload}
                        projectLoaded={projectLoaded}
                        projectName={project?.label || project?.uuid || ''}
                        selected={location.pathname.substring(1)}
                        onSelect={(option: string): void => {
                            navigate(option)
                        }}
                        onShowNotifications={() => {
                            setNotificationsOpen(true)
                            // dispatcher({
                            //     type: 'notification',
                            //     data: {level: 'info', title: 'Test Title', details: 'Test Details'} as GNotification
                            // })
                        }}
                    />                    
                </MenuDrawer> : null}
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? '#fff'
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {withToolbar && <Toolbar variant='dense'/> }
                <Container maxWidth={false} sx={{
                    mt: 0,
                    mb: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 2,
                    overflow: 'hidden',
                    position:'relative',
                    backgroundColor: '#f5f5f5'
                }} disableGutters={true}>

                    {isAuthenticated ?
                        (<Routes>
                            {settingRoutes()}
                
                            <Route path={ROUTE_PREPARATION} element={withProject(<PreparationPage/>)}/>
                            <Route path={ROUTE_HARDWARE} element={withProject(<HardwarePage/>)}/>
                            <Route path={ROUTE_EDITOR} element={withProject(<EditorPageWrapper/>)}/>
                            <Route path={ROUTE_APP} element={withProject(<AppEditor/>)}/>
                            <Route path={ROUTE_UI} element={<Showcase/>}/>
                            <Route path={ROUTE_USER} element={withProject(<UserScreen/>)}/>
                            <Route path="" element={<NewProject/>}/>
                            
                        </Routes>)
                        : <Box sx={{
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex'
                        }}>
                            {isLoading ? 
                            <Stack alignItems={"center"} direction={"column"}><CircularProgress />
                                <Typography>Signing in</Typography>
                            </Stack> : 
                            <Stack alignItems={"center"} direction={"column"}>
                            <Button size='large' color="secondary" onClick={()=>handleLogin()}>Login</Button>
                            {error && <Typography>{error.name} {error.message}</Typography> }
                            </Stack>}
                        </Box>
                    }
                </Container>
            </Box>
        </Stack>

    );
}
